import React from 'react';

const AuthSuccess = () => {
  return (
    <>
      <div className="container d-flex justify-content-center mt-5">
        <div className="h3 textAlignCenter">
          You’re device has been authenticated!
        </div>
      </div>
      <div className="container d-flex justify-content-center mt-5">
        <div className="h5 textAlignCenter">
          You can close this window and proceed work with your device.
        </div>
      </div>
    </>
  );
};

export default AuthSuccess;
