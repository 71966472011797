import React from 'react';
import {
  DATA_PRIVACY_LINK,
  DO_NOT_SELL_MY_PRIVACY_LINK,
  HELP_LINK,
  TERMS_LINK,
} from '../../common/Constants';
const renderFooterSpanItem = (href: string, textContent: string) => {
  return (
    <span className="textAlignCenter">
      <a href={href} target={'_blank'}>
        {textContent}
      </a>
    </span>
  );
};
const RightPanelFooter = () => {
  return (
    <div className="footerDiv mt-xl-3 mt-md-2 mt-5">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex justify-content-center rightPanelFooterContents">
          {renderFooterSpanItem(HELP_LINK, 'Help Center')}
          <span>&nbsp;|&nbsp;</span>
          {renderFooterSpanItem(DATA_PRIVACY_LINK, 'Privacy')}
          <span>&nbsp;|&nbsp;</span>
          {renderFooterSpanItem(TERMS_LINK, 'Terms')}
        </div>
        <div className="d-flex justify-content-center mt-2 rightPanelFooterContents">
          {renderFooterSpanItem(
            DO_NOT_SELL_MY_PRIVACY_LINK,
            'Do Not Sell My Personal Information'
          )}
        </div>
        <div className="d-flex justify-content-center mt-2 rightPanelFooterCopyrights">
          <span>© Copyright 2021, Trimble Inc.</span>
        </div>
      </div>
    </div>
  );
};

export default RightPanelFooter;
