import React from 'react';
import Button from '@trimbleinc/modus-react-bootstrap/esm/Button';
import { IPropTypesBackToAuth } from '../../types/BackToAuthTypes';
const BackToAuth = (props: IPropTypesBackToAuth) => {
  return (
    <div className="container d-flex justify-content-center mt-2">
      <div className="mt-4 backToAuthBtn">
        <Button variant="primary" block={true} onClick={props.reset}>
          Back to Authentication
        </Button>
      </div>
    </div>
  );
};
export default BackToAuth;
