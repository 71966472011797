import React from 'react';
import { IPropTypesBackToAuth } from '../../types/BackToAuthTypes';
import BackToAuth from '../reusable-components/BackToAuthSection';
const AuthFailed = (props: IPropTypesBackToAuth) => {
  return (
    <>
      <div className="container d-flex justify-content-center">
        <img
          src={require('../../assets/FailOne.png')}
          className="img-fluid failureLogo"
          alt="right-panel-failure-logo"
        ></img>
      </div>
      <div className="container d-flex justify-content-center mt-5">
        <div className="h3">Something went wrong!</div>
      </div>
      <div className="container d-flex justify-content-center mt-5 retryTextDiv">
        <div className="h5">Please try authenticating again.</div>
      </div>
      <BackToAuth reset={props.reset} />
    </>
  );
};

export default AuthFailed;
