import React, { useRef, ChangeEvent, ClipboardEvent } from 'react';
import { KeyEventNames } from '../../types/enums/KeyEventEnums';

interface Props {
  userCode: string;
  length?: number;
  onChange?: (userCode: string) => void;
  separator?: React.ReactNode;
  inputStyle?: React.CSSProperties;
  className?: string;
  placeholder?: string;
  allCaps?: boolean;
  onSubmitFunction?: () => void;
}

const CustomUserCodeInput: React.FC<Props> = ({
  userCode,
  length = 6,
  onChange,
  separator = null,
  inputStyle = {},
  className,
  placeholder,
  allCaps,
  onSubmitFunction,
}) => {
  const inputsRef = useRef<HTMLInputElement[]>(Array(length).fill(null));

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = allCaps
      ? event.target.value.toUpperCase()
      : event.target.value;
    const newUserCode = userCode.split('');
    newUserCode[index] = value;
    const nextIndex = index + 1 < length ? index + 1 : null;
    const prevIndex = index - 1 >= 0 ? index - 1 : null;

    //The below code snippet is handling the logic of moving focus to the next or previous input element in the input sequence when a user enters or deletes a character.

    // if the current input value is empty
    if (value === '') {
      // if there is a previous input element
      if (prevIndex !== null) {
        // set focus to the previous input element
        inputsRef.current[prevIndex].focus();
      }
    }
    // if the current input value is not empty
    else {
      // if there is a next input element
      if (nextIndex !== null) {
        // set focus to the next input element
        inputsRef.current[nextIndex].focus();
      }
    }

    if (onChange) {
      onChange(newUserCode.join(''));
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain');
    const truncatedPasteData = pasteData.substring(0, length);
    const newUserCode = userCode.split('');

    for (let i = 0; i < length; i++) {
      newUserCode[i] = allCaps
        ? (truncatedPasteData[i] || '').toUpperCase()
        : truncatedPasteData[i] || '';
    }
    const lastNonEmptyIndex = truncatedPasteData.lastIndexOf('') - 1;
    const nextIndex =
      lastNonEmptyIndex + 1 < length ? lastNonEmptyIndex + 1 : length - 1;

    if (nextIndex !== null) {
      inputsRef.current[nextIndex].focus();
    }

    if (onChange) {
      onChange(newUserCode.join(''));
    }
  };
  const defaultInputStyle: React.CSSProperties = {
    textAlign: 'center',
    ...inputStyle,
  };
  const userCodeArray = Array(length).fill('');

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const prevIndex = index - 1 >= 0 ? index - 1 : null;
    const nextIndex = index + 1 < length ? index + 1 : null;
    if (event.key == userCode.charAt(index)) {
      if (nextIndex !== null) {
        const nextInput = inputsRef.current[nextIndex];
        nextInput.focus();
      }
    } else {
      if (
        event.key === KeyEventNames.BACKSPACE_KEY &&
        !userCode.charAt(index)
      ) {
        if (prevIndex !== null) {
          const prevInput = inputsRef.current[prevIndex];
          prevInput.focus();
        }
      } else if (event.key === KeyEventNames.LEFT_ARROW_KEY) {
        if (prevIndex !== null) {
          const prevInput = inputsRef.current[prevIndex];
          prevInput.focus();
        }
      } else if (event.key === KeyEventNames.RIGHT_ARROW_KEY) {
        if (nextIndex !== null) {
          const nextInput = inputsRef.current[nextIndex];
          nextInput.focus();
        }
      } else if (
        event.key === KeyEventNames.ENTER_KEY &&
        index === length - 1 &&
        onSubmitFunction &&
        userCode.charAt(index)
      ) {
        onSubmitFunction();
      }
    }
  };

  return (
    <div className={`user-code-input ${className}`}>
      {userCodeArray.map((value, index) => (
        <React.Fragment key={index}>
          <input
            type="text"
            maxLength={1}
            placeholder={placeholder}
            value={userCode.charAt(index)}
            onChange={(event) => handleChange(event, index)}
            onPaste={handlePaste}
            ref={(ref) => {
              if (ref) return (inputsRef.current[index] = ref);
            }}
            autoFocus={index == 0 ? true : false}
            style={defaultInputStyle}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onFocus={(e) => {
              // Ensure the input is focused when the user clicks on it or tabs into it
              e.target.focus();
              // Sets up a delay before executing the code inside the callback function.
              // In this case, the delay is set to 0ms, which means it will be executed as soon as possible after the focus() method is called.
              setTimeout(() => {
                const input = e.target;
                // Selects the entire value of the input element by setting the selection range from the start of the input (0)
                // to the end of the input i.e. input.value.length (in the case of userCode, the length inside an input field will be 1).
                input.setSelectionRange(0, input.value.length);
              });
            }}
          />
          {index !== length - 1 && separator}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CustomUserCodeInput;
