import React from 'react';
import PageNotFoundContent from '../reusable-components/PageNotFoundContent';
const NotFound = () => {
  return (
    <>
      <PageNotFoundContent />
    </>
  );
};

export default NotFound;
