import React from 'react';
const PageNotFoundContent = () => {
  return (
    <>
      <div className="container d-flex justify-content-center mt-3">
        <div className="display-1 font-weight-bold errorCode d-flex justify-content-center notFoundCode">
          4<span className="text-trimble-blue">0</span>4
        </div>
      </div>
      <div className="container d-flex justify-content-center mt-5 notFoundDiv">
        <div className="h1 routeNotFoundDiv">Not Found</div>
      </div>
    </>
  );
};
export default PageNotFoundContent;
