import React from 'react';
const LeftPanel = () => {
  return (
    <div className="col-xl-8 d-none d-xl-block col-0 leftPanel leftPanelBg">
      <img
        src={require('../../assets/TrimbleFooterLogo.png')}
        className="img-fluid leftPanelFooterLogo"
        alt="left-panel-footer-logo"
      ></img>
    </div>
  );
};

export default LeftPanel;
