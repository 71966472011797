export const HELP_LINK = 'https://help.trimble.com/s/';
export const DATA_PRIVACY_LINK =
  'https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center';
export const TERMS_LINK =
  'https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use';
export const DO_NOT_SELL_MY_PRIVACY_LINK =
  'https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center?tab=contact_us';
export const SKETCHUP_LINK = 'https://www.sketchup.com/';
export const DEVICE_AUTH_ROUTES = {
  home: '/',
  success: '/success',
  failed: '/failure',
};
export const REDIRECT_PATHNAME = '/oauth/device/activate?userCode=';
