import React from 'react';
import Button from '@trimbleinc/modus-react-bootstrap/Button';
import { REDIRECT_PATHNAME, SKETCHUP_LINK } from '../../common/Constants';
import { IPropTypesUserCode } from '../../types/UserCodeTypes';
import CustomUserCodeInput from '../reusable-components/CustomUserCodeInput';

const userInputStyles = {
  width: '50px',
  height: '40px',
  border: '1px solid #cbcdd6',
  borderBottom: '2px solid #a3a6b1',
};

const UserCode = (props: IPropTypesUserCode) => {
  const redirectToAuthWithUserCode = () => {
    window.open(
      `${window.location.origin}${REDIRECT_PATHNAME}${props.userCode}`,
      '_self'
    );
  };
  return (
    <>
      <div className="container userCodeMainDiv mt-4">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="lead userCodeHeader">
            Type the code shown in your device
          </div>
          {/* User Code */}
          <div className="d-flex justify-content-center  ml-md-0 ml-3 userCodeInputDiv">
            <CustomUserCodeInput
              className="mt-4"
              userCode={props.userCode}
              onChange={props.handleChangeUserCode}
              length={4}
              placeholder={'x'}
              inputStyle={userInputStyles}
              separator={<span className="mx-xl-3 mx-1"></span>}
              allCaps={true}
              onSubmitFunction={redirectToAuthWithUserCode}
            />
          </div>
          {/* CONTINUE btn */}
          <div className="mt-5">
            <Button
              variant="primary"
              disabled={props.disableContinueBtn}
              onClick={redirectToAuthWithUserCode}
            >
              Continue
            </Button>
          </div>
          {/* Thematic break OR */}
          <div className="mt-5 d-flex">
            <div className="line"></div>
            <div className="thematicOR">OR</div>
            <div className="line"></div>
          </div>
        </div>
      </div>
      {/* New User? */}
      <div className="mt-3 d-flex justify-content-center newUserDiv">
        New User?&nbsp;
        <span className="newUserSpan">
          <a href={SKETCHUP_LINK} target={'_blank'}>
            Create an account
          </a>
        </span>
      </div>
    </>
  );
};

export default UserCode;
