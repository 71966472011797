import React from 'react';
import RightPanelFooter from './RightPanelFooter';
import PathBasedRouteRenderer from './PathBasedRouteRenderer';
import { IPropTypesRightPanel } from '../../types/RightPanelTypes';
const RightPanel = (props: IPropTypesRightPanel) => {
  const { userCode, disableContinueBtn, handleChangeUserCode, reset } = props;
  return (
    <div className="col-xl-4 col-12 rightPanel">
      <div className="container d-flex flex-column rightPanelContainer">
        <div>
          <img
            src={require('../../assets/TrimbleBlueLogo.svg').default as string}
            className="img-fluid rightPanelHeaderLogo"
            alt="right-panel-header-logo"
          ></img>
        </div>
        <div className="h1 rightPanelHeading">Device Authentication</div>
        <PathBasedRouteRenderer
          userCode={userCode}
          disableContinueBtn={disableContinueBtn}
          handleChangeUserCode={handleChangeUserCode}
          reset={reset}
        />
        <RightPanelFooter />
      </div>
    </div>
  );
};
export default RightPanel;
