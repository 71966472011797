import { useState } from 'react';

export function useDisableBtn() {
  const [disableContinueBtn, setDisableContinueBtn] = useState(true);
  const handleDisableContinueBtn = (arg: boolean) => {
    setDisableContinueBtn(arg);
  };

  return { disableContinueBtn, handleDisableContinueBtn };
}
