import React from 'react';
import { useLocation } from 'react-router';
import { DEVICE_AUTH_ROUTES } from '../../common/Constants';
import { IPropTypesRightPanel } from '../../types/RightPanelTypes';
import AuthFailed from './AuthFailed';
import AuthSuccess from './AuthSuccess';
import NotFound from './NotFound';
import UserCode from './UserCode';

const PathBasedRouteRenderer = (props: IPropTypesRightPanel) => {
  let location = useLocation().pathname;
  const { userCode, disableContinueBtn, handleChangeUserCode, reset } = props;
  return (
    <>
      {location == DEVICE_AUTH_ROUTES.home && (
        <UserCode
          userCode={userCode}
          disableContinueBtn={disableContinueBtn}
          handleChangeUserCode={handleChangeUserCode}
        />
      )}
      {location == DEVICE_AUTH_ROUTES.success && <AuthSuccess />}
      {location == DEVICE_AUTH_ROUTES.failed && <AuthFailed reset={reset} />}
      {!Object.values(DEVICE_AUTH_ROUTES).includes(location) && <NotFound />}
    </>
  );
};

export default PathBasedRouteRenderer;
