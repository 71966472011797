import React from 'react';
import { useUserCode } from '../../hooks/useUserCode';
import '../../styles/Styles.css';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

const DeviceAuthentication = () => {
  const { userCode, handleChangeUserCode, disableContinueBtn, reset } =
    useUserCode();
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <LeftPanel />
          <RightPanel
            userCode={userCode}
            disableContinueBtn={disableContinueBtn}
            handleChangeUserCode={handleChangeUserCode}
            reset={reset}
          />
        </div>
      </div>
    </>
  );
};

export default DeviceAuthentication;
