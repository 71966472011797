import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DEVICE_AUTH_ROUTES } from '../common/Constants';
import { useDisableBtn } from './useDisableBtn';

export function useUserCode() {
  let history = useHistory();
  const [userCode, setUserCode] = useState('');
  const { disableContinueBtn, handleDisableContinueBtn } = useDisableBtn();
  useEffect(() => {
    handleDisableContinueBtn(!(userCode.length === 4));
  }, [userCode]);
  const handleChangeUserCode = (userCode: string) => {
    setUserCode(userCode);
  };
  //reset to re-attempt User Code Auth in case of a failure
  const reset = () => {
    handleChangeUserCode('');
    handleDisableContinueBtn(true);
    history.push(DEVICE_AUTH_ROUTES.home);
  };
  return { userCode, handleChangeUserCode, disableContinueBtn, reset };
}
